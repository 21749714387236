<template>
    <v-container
            class="esy-chat-container"
            :class="{'moveable': (!chat) }"
            style="background: white; display: flex; align-items: stretch; justify-content: stretch; overflow-x: hidden; height: 100%;"
            fluid :id="!chat ? 'esy-scrollable-panel' : ''">
        <v-row>

            <v-col xs="12" md="3" class="pr-0" style="padding:0;">
                <!-- Chaty -->
                <div v-if="!chat" class="moveable moveable-esy-area" style="position: relative; background: white; padding: 24px; height: calc(100vh - 48px); overflow: auto;">
                    <!-- Loading-->
                    <div v-if="chatLoading" style="pointer-events: none; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
                        <svg class="fa-spin" style="width: 40px; height: 40px; fill: #4084D8;" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M456.433 371.72l-27.79-16.045c-7.192-4.152-10.052-13.136-6.487-20.636 25.82-54.328 23.566-118.602-6.768-171.03-30.265-52.529-84.802-86.621-144.76-91.424C262.35 71.922 256 64.953 256 56.649V24.56c0-9.31 7.916-16.609 17.204-15.96 81.795 5.717 156.412 51.902 197.611 123.408 41.301 71.385 43.99 159.096 8.042 232.792-4.082 8.369-14.361 11.575-22.424 6.92z"/></svg>
                    </div>

                    <div
                            :class="{ 'bubble-faded': chatLoading }"
                            v-for="c in chats"
                            :key="c.id"
                            class="user-bubble"
                            style="
              display: flex;
              gap: 12px;
            "
                            @click="selectChat(c.id)"
                    >
                    <div
                                style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
                        >
                            <v-badge
                                    color="green"
                                    :content="' ' + c.unreadCount"
                                    :value="c.unreadCount"
                            >
                                <v-avatar
                                        size="64"
                                        color="primary"
                                        class="user"
                                        v-if="c && c.search && c.search.user"
                                        :class="{
                    woman:
                      c.search.user.id === user.id
                        ? c.user.sex === 'female'
                        : c.search.user.sex === 'female',
                    man:
                      c.search.user.id === user.id
                        ? c.user.sex !== 'female'
                        : c.search.user.sex !== 'female',
                  }"
                                >
                                    <v-img
                                            v-if="c.search.user.id === user.id && user.profilePicture"
                                            :src="getPictureSource(c.user.profilePicture)"
                                    ></v-img>
                                    <v-img
                                            v-else-if="
                      c.search.user.id !== user.id &&
                      c.search.user.profilePicture
                    "
                                            :src="getPictureSource(c.search.user.profilePicture)"
                                    ></v-img>
                                </v-avatar>
                            </v-badge>
                        </div>
                        <div style="display: flex; align-items: center;">
                            <div
                                    style="font-size: 18px; font-weight: bold;"
                                    v-if="c.user.id == user.id"
                            >
                                {{ getUserName(c.search.user) }}
                            </div>
                            <div style="font-size: 18px; font-weight: bold" v-else>
                                {{ getUserName(c.user) }}
                            </div>
                            <!--
                            <a style="color: black" href="#"
                              >Přejít do našich vzkazů</a
                            > -->
                        </div>
                    </div>

                    <div v-if="chats.length === 0">
                        <div style="font-size: 18px; font-weight: bold">
                            Žádný chat nenalezen.
                        </div>
                    </div>
                </div>

                <!-- Konkrétní  chat-->
                <div v-else class="d-flex flex-column height-100 esy-panel"
                     style="display: flex; max-height: calc(100vh - 48px); /* ZMENA: calc(100vh - 48px); */">
                    <!-- Hlavička -->
                    <div
                            style="
              display: flex;
              flex-direction: column;
            "
                    >
                        <v-toolbar
                                v-if="otherUser"
                                :color="otherUser.sex == 'female' ? 'red' : 'blue'"
                                dark
                                class="d-flex align-center"
                                height="80"
                                style="width: 100%"
                        >
                            <div
                                    class="d-flex justify-space-between align-center pl-3"
                                    style="width: 100%"
                                    v-if="otherUser"
                            >
                                <div class="d-flex flex-column">
                  <span
                          class="text-h6 font-weight-black"
                          style="font-size: 1.5em"
                  >{{ getUserName(chatOtherUser) }}</span
                  >
                                    <a
                                            style="color: white; font-size: 0.8em"
                                            href="#"
                                            @click="selectChat(undefined)"
                                    >{{ $t('Zpět na všechny vzkazy') }}</a
                                    >
                                </div>

                                <v-avatar size="64" color="white" @click="viewMap()">
                                    <v-img
                                            v-if="otherUser.profilePicture"
                                            :src="getPictureSource(otherUser.profilePicture)"
                                    ></v-img>
                                </v-avatar>
                            </div>
                        </v-toolbar>
                    </div>

                    <div ref="chat" id="esy-messages-wrapper" class="moveable" style="flex: 1; overflow: auto;">
                        <div
                                class="pa-5 moveable"

                        >
                            <v-row class="esy-retarded-row moveable">
                                <v-col
                                        cols="12"
                                        v-for="(msg, i) in messages"
                                        :key="i"
                                        :class="{
                    'd-flex flex-column align-end msg-box-wrapper': msg.author === 'me',
                    'd-flex flex-column align-start msg-box-wrapper': msg.author === 'other',
                  }"
                                >
                                    <div
                                            :class="{ 'msg-me': msg.author === 'me', 'msg-box': true }"
                                            style="
                      border-radius: 6px;
                      background-color: #f3f3f3;
                      white-space: normal;
                      padding: 8px;
                      max-width: 80%;
                    "
                                    >
                                        <div

                                                style="
                      
                        text-transform: uppercase;
                        font-size: 0.75em;
                        margin-bottom: 5px;
                        letter-spacing: 0.4px;
                      "
                                        >
                                            {{ msg.author === "me" ? $t('Já') : chatOtherUser.name }},
                                            {{
                                                msg.createdAt.format(
                                                    "DD. MM. YYYY HH:mm"
                                                ) /*msg.createdAt.add(-15, "seconds").fromNow()*/
                                            }}
                                        </div>
                                        <span style="color: black">{{ msg.message }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                    </div>

                    <!-- Panel -->
                    <div id="esy-blbost" style="padding-bottom: 64px; padding-left: 29px; padding-right: 29px;">
                        <v-text-field
                                v-model="message"
                                :rules="[(v) => !!v || 'Zadejte zprávu']"
                                :label="$t('Zpráva')"
                                hide-details
                                outlined
                                @keyup.enter="sendMessage"
                                ref="messageBlock"
                                style="margin-bottom: 12px; margin-top: 24px;"
                        ></v-text-field>

                        <v-btn
                                block
                                color="green"
                                dark
                                x-large
                                class="font-weight-bold"
                                @click="sendMessage"
                        >
                            {{ $t('Odeslat') }}
                        </v-btn>
                    </div>

                    <!--
                    <div class="pa-5 mb-5">
                      <v-text-field
                        v-model="message"
                        :rules="[(v) => !!v || 'Zadejte zprávu']"
                        label="Zpráva"
                        hide-details
                        class="mb-2"
                        outlined
                        @keyup.enter="sendMessage"
                        ref="messageBlock"
                      ></v-text-field>

                      <v-btn
                        style="width: 100%"
                        block
                        color="green"
                        dark
                        x-large
                        class="font-weight-bold"
                        @click="sendMessage"
                      >
                        ODESLAT
                      </v-btn>
                    </div> -->
                </div>
            </v-col>

            <!-- Mapa -->
            <v-col
                    cols="9"
                    class="d-none d-md-block height-100 map-side pa-0 attachTo"
                    ref="map-holder"
            >
                <div ref="map" class="map-holder"></div>

                <div class="info-holder" v-if="otherUser && chat">
                    <!-- Profil -->
                    <v-card v-if="holderMode == 'search'">
                        <v-card-title
                                class="white--text pa-2"
                                :class="{
                blue: otherUser.sex != 'female',
                red: otherUser.sex == 'female',
              }"
                        >

                            <div @click="holderMode = holderMode == 'profile' ? 'search' : 'profile'" class="clickable-holder">
                                <v-avatar size="64" color="white" class="mr-3">
                                    <v-img
                                            v-if="otherUser.profilePicture"
                                            :src="getPictureSource(otherUser.profilePicture)"
                                    ></v-img>
                                </v-avatar>
                                <span>{{ getUserNameClassic(otherUser) }}</span>
                            </div>

                            <v-spacer></v-spacer>

                            <v-btn icon color="white" @click="selectChat(undefined)">
                                <v-icon>fal fa-times</v-icon>
                            </v-btn>
                        </v-card-title>

                        <v-card-text class="px-5 pt-5 text-body-1" style="color: black">
                            <v-row>
                                <v-col cols="8">
                                    <p class="mb-1"><b>{{ $t('Místo setkání') }}</b></p>

                                    <p>{{ chat.search.address }}</p>
                                </v-col>
                                <v-col cols="4">
                                    <p class="mb-1"><b>{{ $t('Termín setkání') }}</b></p>

                                    <p>{{ chat.search.seenAt.fromNow() }}</p>
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-card-text
                                style="height: 200px; color: black"
                                class="px-5 pt-0 text-body-1 moveable"
                        >
                            <p class="mb-1"><b>{{ $t('Popis protějšku') }}</b></p>

                            <p class="mb-2">{{ chat.search.description }}</p>

                            <p class="mb-1"><b>{{ $t('Situace při setkání') }}</b></p>

                            <p class="mb-2">{{ chat.search.situation }}</p>

                            <p class="mb-1"><b>{{ $t('Zpráva') }}</b></p>

                            <p class="mb-2">{{ chat.search.message }}</p>
                        </v-card-text>

                        <v-card-actions class="d-flex align-center px-5 pb-5">
                            <p class="mb-0">
                                <small>{{ $t('Počet zobrazení') }}: {{ chat.search.views }}</small>
                            </p>
                            <v-spacer></v-spacer>
                            <p
                                    v-if="chat.search.active"
                                    class="green--text font-weight-bold mb-0"
                            >
                                {{ $t('Hledání je aktivní') }}
                            </p>
                            <p v-else class="red--text font-weight-bold mb-0">
                                {{ $t('Hledání bylo ukočeno') }}
                            </p>
                        </v-card-actions>
                    </v-card>
                    <!-- Hledání -->
                    <v-card v-if="holderMode == 'profile'">
                        <v-card-title
                                class="white--text pa-2"
                                :class="{
                blue: otherUser.sex != 'female',
                red: otherUser.sex == 'female',
              }"
                        >

                            <div class="clickable-holder" @click="holderMode = holderMode == 'search' ? 'profile' : 'search'">
                                <v-avatar size="64" color="white" class="mr-3">
                                    <v-img
                                            v-if="otherUser.profilePicture"
                                            :src="getPictureSource(otherUser.profilePicture)"
                                    ></v-img>
                                </v-avatar>
                                <span>Zpět na hledání</span>
                            </div>

                            <v-spacer></v-spacer>

                            <!--
                            <v-btn style="margin-right: 12px; color: white; background: transparent; box-shadow: none;"
                                   variant="text" @click="holderMode = 'search'">
                                {{ $t('Zpět na hledání') }}
                            </v-btn> -->

                            <v-btn icon color="white" @click="selectChat(undefined)">
                                <v-icon>fal fa-times</v-icon>
                            </v-btn>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-row justify="space-between">
                                    <v-col>
                                        <v-list>
                                            <v-list-item style="padding:0; min-height: 32px;">
                                                <v-list-item-title>
                                                    <b>{{ $t('Pohlaví') }}: </b> {{
                                                        otherUser.sex === "female" ?
                                                            $t('žena') : $t('muž')
                                                    }}
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item style="padding:0; min-height: 32px;">
                                                <v-list-item-title>
                                                    <b>{{ $t('Jméno') }}: </b> {{ otherUser.firstName }} {{
                                                        otherUser.lastName
                                                    }}
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item style="padding:0; min-height: 32px;">
                                                <v-list-item-title>
                                                    <b>{{ $t('Věk') }}: </b> {{
                                                        today.diff(otherUser.birthDate,
                                                            "year")
                                                    }}
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item style="padding:0; min-height: 32px;">
                                                <v-list-item-title>
                                                    <b>{{ $t('Bydliště') }}: </b> {{ otherUser.city }}
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item style="padding:0; min-height: 32px;">
                                                <v-list-item-title>
                                                    <b>{{ $t('Aktuální stav') }}: </b>
                                                    <v-chip color="green">{{ $t('Aktivní') }}</v-chip>
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                </v-row>

                                <v-row class="my-0 mt-3 py-0">
                                    <v-col cols="12" sm="auto" class="py-0">
                                        <p class="mb-0"><b>{{ $t('Členem od') }}: </b> {{
                                                otherUser.createdAt.format("DD. MM. YYYY HH:mm:ss")
                                            }}
                                        </p>
                                    </v-col>
                                    <v-col cols="12" sm="auto" class="py-0">
                                        <p class="mb-0">
                                            <b>{{ $t('Poslední přihlášení') }}: </b>
                                            {{ otherUser.lastAuth.format("DD. MM. YYYY HH:mm:ss") }}
                                        </p>
                                    </v-col>
                                </v-row>

                                <span class="report-user" @click="reportDialog = true;">{{
                                        $t('Nahlásit uživatele')
                                    }}</span>
                            </v-container>
                        </v-card-text>

                        <v-divider/>

                        <v-card-text>
                            <v-container>
                                <div class="moveable" style="height: 100px;">
                                
                                <div class="image-holder" style="width: 80px; height: 80px; float: left; margin-right: 6px; margin-bottom: 6px;">
                                    <img @click="zoomedImage = getPictureSource(otherUser.profilePicture); imageDialog = true;" style="object-fit: cover;" :src="getPictureSource(otherUser.profilePicture)">
                                </div>
                                    <div class="image-holder"
                                         style="width: 80px; height: 80px; float: left; margin-right: 6px; margin-bottom: 6px;"
                                         v-for="(picture, i) in otherUser.pictures" :key="i">
                                        <img @click="zoomedImage = getPictureSource(picture); imageDialog = true;"
                                             style="object-fit: cover;" :src="getPictureSource(picture)">
                                    </div>
                                </div>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </div>
            </v-col>
        </v-row>

        <!-- Mapa (phone only) -->
        <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card class="esy-card-parent" style="background: black;">
                <v-toolbar dark color="primary" class="esy-card-toolbar">
                    <v-toolbar-title>{{ $t('Místo setkání') }}</v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-btn @click="dialog = false" icon color="white">
                        <v-icon>fal fa-times</v-icon>
                    </v-btn>
                </v-toolbar>

                <div style="pointer-events: none; opacity: .7" class="esy-card-body h-100" ref="map-dialog">
                </div>

                <div style="bottom: 0; left: 0; right: 0; position: absolute; background: white; border-radius: 8px;">
                    <v-card-text style="padding: 0;">
                        <v-tabs background-color="dark" v-model="tab" centered center-active dark>
                            <v-tab key="profile_info">{{ $t('Profilové údaje') }}</v-tab>
                            <v-tab key="search_info">{{ $t('Hledání') }}</v-tab>
                        </v-tabs>

                        <v-tabs-items v-model="tab" :show-arrows="false">
                            <!-- Info -->
                            <v-tab-item key="profile_info">
                                <v-container v-if="otherUser">
                                    <v-row justify="space-between">
                                        <v-col>
                                            <v-list>
                                                <v-list-item style="padding:0; min-height: 32px;">
                                                    <v-list-item-title>
                                                        <b>{{ $t('Pohlaví') }}: </b> {{
                                                            otherUser.sex === "female" ?
                                                                $t('žena') : $t('muž')
                                                        }}
                                                    </v-list-item-title>
                                                </v-list-item>
                                                <v-list-item style="padding:0; min-height: 32px;">
                                                    <v-list-item-title>
                                                        <b>{{ $t('Jméno') }}: </b> {{ otherUser.firstName }} {{
                                                            otherUser.lastName
                                                        }}
                                                    </v-list-item-title>
                                                </v-list-item>
                                                <v-list-item style="padding:0; min-height: 32px;">
                                                    <v-list-item-title>
                                                        <b>{{ $t('Věk') }}: </b> {{
                                                            today.diff(otherUser.birthDate,
                                                                "year")
                                                        }}
                                                    </v-list-item-title>
                                                </v-list-item>
                                                <v-list-item style="padding:0; min-height: 32px;">
                                                    <v-list-item-title>
                                                        <b>{{ $t('Bydliště') }}: </b> {{ otherUser.city }}
                                                    </v-list-item-title>
                                                </v-list-item>
                                                <v-list-item style="padding:0; min-height: 32px;">
                                                    <v-list-item-title>
                                                        <b>{{ $t('Aktuální stav') }}: </b>
                                                        <v-chip color="green">{{ $t('Aktivní') }}</v-chip>
                                                    </v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                            <span class="report-user"
                                                  @click="reportDialog = true;">{{ $t('Nahlásit uživatele') }}</span>
                                        </v-col>
                                    </v-row>

                                    <v-row class="my-0 mt-3 py-0">
                                        <v-col cols="12" sm="auto" class="py-0">
                                            <p class="mb-0"><b>{{ $t('Členem od') }}: </b> {{
                                                    otherUser.createdAt.format("DD. MM. YYYY HH:mm:ss")
                                                }}
                                            </p>
                                        </v-col>
                                        <v-col cols="12" sm="auto" class="py-0">
                                            <p class="mb-0">
                                                <b>{{ $t('Poslední přihlášení') }}: </b>
                                                {{ otherUser.lastAuth.format("DD. MM. YYYY HH:mm:ss") }}
                                            </p>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-divider/>
                                <v-card-text style="padding: 0;">
                                    <v-container v-if="otherUser">
                                        <div class="moveable" style="height: 100px;">

                                <div class="image-holder" style="width: 80px; height: 80px; float: left; margin-right: 6px; margin-bottom: 6px;">
                                    <img @click="zoomedImage = getPictureSource(otherUser.profilePicture); imageDialog = true;" style="object-fit: cover;" :src="getPictureSource(otherUser.profilePicture)">
                                </div>
                                            <div class="image-holder"
                                                 style="width: 80px; height: 80px; float: left; margin-right: 6px; margin-bottom: 6px;"
                                                 v-for="(picture, i) in otherUser.pictures" :key="i">
                                                <img @click="zoomedImage = getPictureSource(picture); imageDialog = true;"
                                                     style="object-fit: cover;" :src="getPictureSource(picture)">
                                            </div>
                                        </div>
                                    </v-container>
                                </v-card-text>
                            </v-tab-item>

                            <!-- Search info-->
                            <v-tab-item key="search_info">
                                <v-card-text v-if="chat" class="px-5 pt-5 text-body-1" style="color: black">
                                    <v-row>
                                        <v-col cols="12">
                                            <p class="mb-1"><b>{{ $t('Místo setkání') }}</b></p>
                                            <p>{{ chat.search.address }}</p>

                                            <p class="mb-1"><b>{{ $t('Datum setkání') }}</b></p>

                                            <p>{{ chat.search.seenAt.fromNow() }}</p>

                                            <p class="mb-1"><b>{{ $t('Popis protějšku') }}</b></p>

                                            <p class="mb-2">{{ chat.search.description }}</p>

                                            <p class="mb-1"><b>{{ $t('Situace při setkání') }}</b></p>

                                            <p class="mb-2">{{ chat.search.situation }}</p>

                                            <p class="mb-1"><b>{{ $t('Zpráva') }}</b></p>

                                            <p class="mb-2">{{ chat.search.message }}</p>
                                        </v-col>
                                    </v-row>
                                </v-card-text>

                                <v-card-actions v-if="chat" class="d-flex align-center px-5 pb-5">
                                    <p class="mb-0">
                                        <small>{{ $t('Počet zobrazení') }}: {{ chat.search.views }}</small>
                                    </p>
                                    <v-spacer></v-spacer>
                                    <p
                                            v-if="chat.search.active"
                                            class="green--text font-weight-bold mb-0"
                                    >
                                        {{ $t('Požadavek je aktivní') }}
                                    </p>
                                    <p v-else class="red--text font-weight-bold mb-0">
                                        {{ $t('Požadavek byl ukočen') }}
                                    </p>
                                </v-card-actions>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card-text>
                </div>
            </v-card>
        </v-dialog>

        <!-- Obrázek -->
        <v-dialog v-model="imageDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card class="esy-card-parent">
                <v-toolbar dark color="black" class="esy-card-toolbar">
                    <v-toolbar-title>{{ $t('Obrázek') }}</v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-btn @click="zoomedImage = null; imageDialog = false;" icon color="white">
                        <v-icon>fal fa-times</v-icon>
                    </v-btn>
                </v-toolbar>

                <div class="esy-card-body h-100" style="display: flex; align-items: center; justify-content: center;">
                    <img :src="zoomedImage" style="background-color: black; object-fit: contain;">
                </div>
            </v-card>
        </v-dialog>

        <!-- Report dialog -->
        <v-dialog v-model="reportDialog" width="unset" hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-toolbar-title style="margin-right: 80px;">{{ $t('Nahlásit uživatele') }}</v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-btn @click="reportDialog = false;" icon color="white">
                        <v-icon>fal fa-times</v-icon>
                    </v-btn>
                </v-toolbar>

                <div class="h-100"
                     style="padding: 24px; display: flex; justify-content: center; flex-direction: column;">
                    <v-textarea
                            auto-grow
                            v-model="details"
                            :label="$t('Podrobnosti')"
                            :rules="[(v) => !!v || '', (v) => (v && v.length <= 500) || '']"
                            required
                    ></v-textarea>

                    <v-btn color="red" dark @click="reportUser();" elevation="8" style="margin-top: 24px;">
                        {{ $t('Nahlásit') }}
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
import moment from "moment";
import {URL} from "@/store/index";
import { App } from '@capacitor/app';


const {io} = require("socket.io-client");

export default {
    name: "ChatList",

    computed: {
        user() {
            return this.$store.state.user;
        },

        chat() {
            return this.$store.state.chat;
        },

        chatOtherUser() {
            if (!this.chat) return null;

            return this.chat.search.user.id === this.user.id
                ? this.chat.user
                : this.chat.search.user;
        },

        chats() {
            return this.$store.state.chats.sort((a, b) => b.unreadCount - a.unreadCount);
        },
    },

    data: () => ({
        today: moment(),

        holderMode: 'search', // nebo profile

        dialogUser: false,
        dialogWithWho: false,
        selectedChat: null,
        zoomedImage: null,
        imageDialog: false,

        message: "",

        socket: null,
        messages: [],

        otherUser: null,

        map: undefined,
        markers: [],

        first: true,

        dialog: false,
        reportDialog: false,
        details: '',

        tab: 'profile_info',
        chatLoading: false
    }),

    async mounted() {

        App.removeAllListeners().then(() => {
            App.addListener('backButton', () => {
                console.log('Back button from CHAT!');
                console.log('selectedChat is ', this.selectedChat);
                console.log('chat is ', this.chat);
                /*
                console.log('chat id is ', this.chat.id);
                */

                if (this.chat)
                    this.selectChat(undefined);
                else
                    window.history.back();
            });
        });

        setTimeout(() => {
            const map = new google.maps.Map(this.$refs.map, {
                zoom: 12,
                center: {
                    lat: 50.0743105,
                    lng: 14.4163701,
                },
                disableDefaultUI: true,
                streetViewControl: false,
                gestureHandling: "greedy",
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: [
                    {
                        featureType: "poi",
                        stylers: [
                            {
                                visibility: "off",
                            },
                        ],
                    },
                    {
                        featureType: "transit",
                        stylers: [
                            {
                                visibility: "off",
                            },
                        ],
                    },
                    /*
                    {
                      featureType: "administrative",
                      stylers: [
                        {
                          visibility: "off",
                        },
                      ],
                    }, */
                    {
                        featureType: "landscape.natural",
                        elementType: "labels",
                        stylers: [
                            {
                                visibility: "off",
                            },
                        ],
                    },
                ],
            });

            this.map = map;

            this.createMarks();
        }, 600);

        await this.$store.dispatch("getChats");

        if (this.chat && this.chat.id) {
            this.selectChat(this.chat.id);
        } else {
            if (this.chats.length > 0) {
                //    this.selectChat(this.chats[0].id);
            }
        }

        setInterval(async () => {
            await this.$store.dispatch("getChats");
        }, 15000);
    },

    watch: {
        dialog: function () {
            this.$nextTick(() => {

                if (this.dialog) {
                    // move this.$refs.map element to this.$refs.map-dialog
                    this.$refs["map-dialog"].innerHTML = "";
                    this.$refs["map-dialog"].append(this.map.getDiv());
                } else {
                    this.$refs["map-holder"].innerHTML = "";
                    this.$refs["map-holder"].append(this.map.getDiv());
                }
            });
        },
    },

    methods: {
        getUserName(user) {
            if (user.name != '- -')
                return user.name;

            return user.nickname;
        },
        getUserNameClassic(user) {
            if (user.firstName != '-' && user.lastName != '-')
                return `${user.firstName} ${user.lastName}`;

            if (user.firstName != '-')
                return user.firstName;

            if (user.lastName != '-')
                return user.lastName;

            return user.nickName;
        },
        async reportUser() {
            try {
                const success = await this.$store.dispatch('reportUser', {
                    reported: this.otherUser.id,
                    details: this.details
                });

                if (!success) throw new Error("Nepodařilo se nahlásit uživatele.");

                this.$root.$emit('notification', {
                    message: this.$t("Děkujeme, uživatel byl úspěšně nahlášen."),
                    duration: 5
                });

                this.reportDialog = false;

                this.details = "";
            } catch (e) {
                this.$root.$emit('notification', {
                    message: this.$t("Nepodařilo se nahlásit uživatele. Nejspíše jste uživatele již nahlásili."),
                    type: "error",
                    duration: 5
                });
            }
        },

        getPictureSource(picture) {
            return URL + picture;
        },

        viewMap() {
            if (this.$vuetify.breakpoint.name === 'md' || this.$vuetify.breakpoint.name === 'lg' || this.$vuetify.breakpoint.name === 'xl')
                return;

            this.dialog = !this.dialog;

            const marker = this.markers.find(m => m.chat.id === this.selectedChat);
            console.log(this.selectedChat);

            const downedPosition = {
                lat: marker.marker.position.lat() - 0.003,
                lng: marker.marker.position.lng(),
            };

            this.map.setCenter(downedPosition);

            this.map.setZoom(15);
        },

        async selectChat(chat) {
            this.selectedChat = chat;

            this.dialogWithWho = false;
            this.messages = [];
            if (this.socket) {
                this.socket.disconnect();
            }

            if (chat === undefined) {
                this.$store.commit("setChat", undefined);
                return;
            }
            // find marker for this chat
            // console.log(this.markers);
            const marker = this.markers.find((m) => m.chat.id === chat);

            if (marker) {
                const downedPosition = {
                    lat: marker.marker.position.lat() + 0.01,
                    lng: marker.marker.position.lng(),
                };

                this.map.setCenter(downedPosition);

                this.map.setZoom(15);
            }

            //console.log("creating socket?");
            const socket = io(URL, {
                auth: {
                    token: this.$store.state.token,
                },
                query: {
                    chatId: chat,
                },
            });

            this.socket = socket;

            const that = this;

            let timeout = setTimeout(() => {
                this.chatLoading = true;
            }, 750);

            socket.on("connect", () => {
                //console.log("connect?");

                // socket.onAny((event, ...args) => {
                //   console.log(event, args);
                // });

                socket.on("chat", (data) => {
                    //console.log("recieved chat", data);
                    this.$store.commit("setChat", {
                        ...data.chat,
                        search: {
                            ...data.chat.search,
                            seenAt: moment(data.chat.search.seenAt),
                        },
                    });
                });

                socket.on("otherUser", (data) => {
                    //console.log("recieved other user", data);
                    that.otherUser = {
                        ...data,
                        lastAuth: moment(data.lastAuth),
                        createdAt: moment(data.createdAt),
                        birthDate: moment(data.birthDate),
                    };
                });

                socket.on("messages", (data) => {
                    clearTimeout(timeout);
                    this.chatLoading = false;

                    console.log("recieved messages", data);

                    this.first = true;
                    //console.log("recieved messages", data);
                    for (let msg of data.messages) {
                        //console.log(msg);
                        //console.log(this.messages.find(m => m._id === msg._id));
                        if (this.messages.find(m => m._id === msg._id)) continue;

                        this.messages.push({
                            ...msg,
                            author: msg.author === this.user.id ? "me" : "other",
                            createdAt: moment(msg.createdAt),
                        });
                    }

                    if (this.$refs.chat.scrollTop != 0)
                        setTimeout(() => {
                            this.$nextTick(() => {
                                this.$refs.chat.scrollTo({
                                    top: this.$refs.chat.scrollHeight,
                                    behavior: "smooth",
                                });
                            });
                        }, 500);

                    // Sort messages by createdAt
                    this.messages = this.messages.sort((a, b) => {
                        return a.createdAt - b.createdAt;
                    });
                });
            });

            setTimeout(() => {
                if (socket) socket.emit("chat", "getMessages");
            }, 200);

            const f = () => {
                if (!this.$refs.chat) {
                    setTimeout(f, 100);
                    return;
                }

                /**
                 *             const scrollBefore =
                 that.$refs.chat.scrollHeight - that.$refs.chat.scrollTop;
                 const scrollHeightBefore = that.$refs.chat.scrollHeight;
                 that.$nextTick(() => {
            if (!that.$refs.chat) return;
            if (that.$refs.chat.scrollTop == 0) {
              that.$refs.chat.scrollTo({
                top: that.$refs.chat.scrollHeight - scrollHeightBefore,
              });
            } else if (scrollBefore < window.innerHeight * 0.8 || first)
              that.$refs.chat.scrollTo({
                top: this.$refs.chat.scrollHeight,
                behavior: "smooth",
              });
          });
                 */

                this.$refs.chat.addEventListener("scroll", () => {
                    if (!this.$refs.chat)
                        return;

                    if (this.$refs.chat.scrollTop === 0) {
                        const before = this.$refs.chat.scrollHeight - this.$refs.chat.scrollTop;
                        this.getMessages();

                        this.$nextTick(() => {
                            setTimeout(() => {
                                const after = this.$refs.chat.scrollHeight - this.$refs.chat.scrollTop;
                                this.$refs.chat.scrollTo({
                                    top: after - before
                                });
                            }, 250);
                        });
                    }
                });

                if (!this.first)
                    return;

                this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight;
                this.first = false;
            };

            this.$nextTick(f);
        },

        async sendMessage() {
            console.log("sending message", this.message);
            console.log(this.socket);

            if (this.socket) {
                this.socket.emit("message", {
                    message: this.message,
                });
            }

            this.message = "";
            this.$refs.messageBlock.resetValidation();

            setTimeout(() => {
                this.$nextTick(() => {
                    this.$refs.chat.scrollTo({
                        top: this.$refs.chat.scrollHeight,
                        behavior: "smooth",
                    });
                });
            }, 500);
        },

        async getMessages() {
            if (this.socket) {
                this.socket.emit("messages", {
                    oldest: this.messages[0].createdAt.toISOString(),
                });
            }
        },

        createMarks() {
            //console.log("creating marks");
            for (let chat of this.chats) {
                //console.log(chat);
                if (chat.search && chat.search.lan && chat.search.lon) {
                    let female =
                        chat.search.user.id === this.user.id
                            ? chat.user.sex === "female"
                            : chat.search.user.sex === "female";

                    const marker = new google.maps.Marker({
                        position: {
                            lat: chat.search.lan,
                            lng: chat.search.lon,
                        },
                        map: this.map,
                        icon: female ? "/img/map-marker.png" : "/img/map-marker-men.png",
                    });

                    this.markers.push({
                        marker,
                        chat,
                    });

                    marker.addListener("click", () => {
                        this.selectChat(chat.id);
                    });
                }
            }
        },
    },
};
</script>

<style lang="scss">

.esy-card-body .map-holder {
    position: static !important;
}

.esy-card-parent {
    display: flex;
    flex-direction: column;
}

.esy-card-body {
    flex-grow: 1;
    background: black;
    display: flex;
}

.esy-card-toolbar {
    flex: 0 !important;
    z-index: 999;
}

.clickable-holder:hover {
    filter: brightness(0.8);
    cursor: pointer;
}

.info-holder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: rgba(0, 0, 0, 0.2);

    .v-card {
        width: 55%;

        transform: translateY(-15%);
    }
}

.chat {
    height: 65vh;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: #f3f3f3;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #7a9704;
        border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

.moveable {
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: #f3f3f3;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #7a9704;
        border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

.map-holder {
    cursor: url("/img/cross.png"), pointer;
    position: relative;
    height: 100% !important;
}

.height-100 {
    height: 100%;
}

.esy-panel * {
    box-sizing: border-box;
}

.map-side {
    position: relative;
    height: calc(100vh - 48px); // ZMENA: calc(100vh - 48px);

    .backToPosition {
        position: absolute;
        bottom: 60px;
        left: 20px;
    }
}

.v-toolbar__content {
    width: 100%;
}

.msg-me {
    background: #4084D8 !important;
}

.user-bubble {
    padding: 8px;
    border-bottom: 1px solid #F2F2F2;
}

.bubble-faded {
    opacity: .35;
    pointer-events: none;
}

.user-bubble:hover {
    cursor: pointer;
    background: #F2F2F2 !important;
    border-radius: 8px;
}

.msg-me div {
    color: rgba(255, 255, 255, .55) !important;
}

.msg-me span {
    color: white !important;
}

.msg-box div {
    color: rgba(0, 0, 0, .55);
}

.msg-box-wrapper {
    padding: 6px !important;
}

.moveable-esy-area {
    height: calc(100vh - 50px - 48px) !important; // - kvůli footeru
}

@media only screen and (max-width: 992px) {
    .moveable-esy-area {
        height: calc(100vh - 48px) !important;
    }

    .mobile-insets .moveable-esy-area {
        height: auto !important;
    }

    .mobile-insets .moveable-esy-area {
        height: calc(100vh - 96px) !important;
    }

    #esy-blbost {
        border-top: 1px solid #dedede;
        padding: 24px !important;
        padding-top: 0 !important;
    }

    .esy-panel {
        height: calc(100vh - 48px) !important; // ZMENA: calc(100vh - 96px) !important;
    }

    .mobile-insets .esy-panel {
        height: calc(100vh - 96px) !important;
    }

    .esy-panel > div {
        margin: 0 !important;
    }

    .mobile-insets .esy-chat-container {
        height: auto !important;
    }
}

> .moveable {
    flex-grow: 2;
    overflow-x: auto;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: #f3f3f3;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #7a9704;
        border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

.image-holder {
    width: 100px;
    height: 100px;
    border: 1px solid #dedede;
    padding: 4px;
    border-radius: 4px;
}

.image-holder:hover {
    cursor: pointer;
}

.image-holder img {
    width: 100%;
    height: 100%;
}

.v-dialog {
    overflow: hidden !important;
}

.report-user {
    color: #F44336;
    font-weight: bold;
}

.report-user:hover {
    text-decoration: underline;
    cursor: pointer;
}
</style>