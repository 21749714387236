<template>
    <div class="pa-0 height-100">
        <v-row class="height-100 remove-row" align="center">
            <v-col cols="12" md="3" class="side-container moveable white esy-neco-2" style="height: calc(100vh - 50px); transform: translate(0, -1px); overflow-y: scroll;">
                <!--
                <v-row class="side remove-row">
                    <v-col cols="12">
                        <p>
                            Zadejte údaje o setkání a vyplňte prosím jednotlivé
                            kroky, díky kterým lépe najdete hledanou osobu.
                        </p>
                    </v-col>
                </v-row>
                -->
                <v-row class="side remove-row moveable esy-oprava esy-shite">
                    <v-col cols="12">
                        <p class="label">{{ $t('Místo setkání') }}</p>
                        <v-autocomplete :placeholder="$t('Zadejte adresu')" :value="value" :items="virtualItems"
                            :search-input.sync="search" :loading="loading" :hide-no-data="loading || !search"
                            item-text="main_text" item-value="place_id" return-object outlined hide-details no-filter
                            :no-data-text="$t('Žádné výsledky')" @input="selected" background-color="white"
                            class="mb-4">
                            <template v-slot:item="data">
                                <template>
                                    <v-list-item-content>
                                        <v-list-item-title v-html="data.item.main_text"></v-list-item-title>
                                        <v-list-item-subtitle v-html="data.item.secondary_text"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </template>
                        </v-autocomplete>

                        <v-alert color="blue" dark class="
                                d-none d-md-flex
                                justify-space-between
                                font-weight-black
                                map-alert
                            " dense
                            @click="backToPosition">
                            <div class="d-flex align-center justify-space-between text-center" style="width: 100%;">
                                {{ $t('nebo klikněte do mapy') }}
                                <img src="/img/map-into.png" class="map-icon" />
                            </div>
                        </v-alert>

                        <v-btn color="blue" dark class="
                                esy-cool-button
                                text-right
                                d-block d-md-none
                                mb-1
                                font-weight-bold
                            " block x-large @click="dialog = true">
                            {{ $t('nebo vyberte na mapě') }}
                        </v-btn>

                        <v-divider />
                    </v-col>
                    <v-col cols="12">
                        <p class="label date">{{ $t('Datum a čas setkání') }}</p>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                        <v-menu ref="menu" v-model="menu" :close-on-content-click="true" transition="scale-transition"
                            offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field placeholder="12. 10. 2017" readonly :value="formatDate" outlined
                                    v-bind="attrs" hide-details v-on="on" background-color="white">
                                </v-text-field>
                            </template>
                            <v-date-picker v-model="date" :max="
                                new Date(
                                    Date.now() -
                                    new Date().getTimezoneOffset() *
                                    60000
                                )
                                    .toISOString()
                                    .substr(0, 10)
                            " min="1950-01-01" @input="dateChanged()"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0 pt-3 pt-md-0">
                        <v-menu ref="menu" v-model="menu2" :close-on-content-click="false" :return-value.sync="time"
                            transition="scale-transition" offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="time" placeholder="12 : 32" readonly outlined format="24hr"
                                    v-bind="attrs" v-on="on" background-color="white" hide-details></v-text-field>
                            </template>
                            <v-time-picker v-if="menu2" v-model="time" format="24hr"
                                @click:minute="$refs.menu.save(time)">
                            </v-time-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" @mousedown="focused = 'other'">
                        <p class="label">{{ $t('Popis protějšku') }}</p>
                        <v-textarea outlined :class="{
                            'focused': focused === 'other'
                        }" @focus="focused = 'other'" @mousedown="focused = 'other'" @blur="focused = ''" no-resize
                            background-color="white" hide-details :placeholder="$t('Popište protějšek')" v-model="description">
                        </v-textarea>
                    </v-col>
                    <v-col cols="12" @mousedown="focused = 'situation'">
                        <p class="label">{{$t('Situace při setkání')}}</p>
                        <v-textarea :placeholder="$t('Popište vaše setkání')" v-model="situation" hide-details outlined
                            background-color="white" no-resize :class="{
                                'focused': focused === 'situation'
                            }" @focus="focused = 'situation'" @mousedown="focused = 'situation'"
                            @blur="focused = ''" />
                    </v-col>
                    <v-col cols="12" @mousedown="focused = 'message'">
                        <p class="label">{{$t('Případný vzkaz')}}</p>
                        <v-textarea :placeholder="$t('Napište protějšku vzkaz')" v-model="message" hide-details outlined
                            no-resize background-color="white" :class="{
                                'focused': focused === 'message'
                            }" @focus="focused = 'message'" @mousedown="focused = 'message'" @blur="focused = ''" />
                    </v-col>
                </v-row>
                <v-row class="side remove-row">
                    <v-col cols="12">
                        <v-btn block color="green" dark x-large class="font-weight-black" @click="post">
                            {{$t('Vyvěsit hledání')}}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="d-none d-md-block height-100 map-side pa-0" cols="12" md="9" ref="map-holder">
                <div ref="map" class="map-holder"></div>

                <v-btn color="primary" fab large dark x-small class="backToPosition" @click="backToPosition">
                    <v-icon>fal fa-location</v-icon>
                </v-btn>
            </v-col>

            <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
                <v-card>
                    <v-toolbar dark color="primary" style="z-index: 999;">
                        <v-toolbar-title>{{$t('Vyberte místo')}}</v-toolbar-title>

                        <v-spacer></v-spacer>
 
                        <v-btn icon color="white" @click="dialog = false">
                        <v-icon>fal fa-times</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <div class="h-100 map-mobile" ref="map-dialog"></div>

                    <div style="bottom: 50px; left: 0; position: absolute">
                        <v-btn color="success" dark absolute bottom left fab @click="dialog = false" elevation="8">
                            <v-icon>fal fa-check</v-icon>
                        </v-btn>
                    </div>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: "SearchNew",

    computed: {
        user() {
            return this.$store.state.user;
        },
        virtualItems() {
            if (this.items.length === 0 && this.value) {
                return [this.value];
            } else {
                return this.items;
            }
        },
        formatDate() {
            return this.date
                ? moment(this.date).format("D. M. YYYY")
                : "";
        }
    },

    data: () => ({
        focused: '',

        date: new Date().toISOString().substr(0, 10),
        menu: undefined,
        menu2: undefined,
        time: `${(new Date()).getHours().toString().padStart(2, '0')}:${(new Date()).getMinutes().toString().padStart(2, '0')}`,

        message: "",
        situation: "",
        description: "",

        dialog: false,

        searchTimeout: undefined,

        map: undefined,
        marker: undefined,

        autocomplete: undefined,
        geocoder: undefined,

        items: [],
        search: "",
        value: "",
        loading: false,
    }),

    mounted() {
        setTimeout(() => {
            const map = new google.maps.Map(this.$refs.map, {
                zoom: 12,
                center: {
                    lat: 50.0743105,
                    lng: 14.4163701,
                },
                disableDefaultUI: true,
                streetViewControl: false,
                gestureHandling: "greedy",

                draggableCursor: 'url("/img/cross.png") 35 35, auto',
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: [
                    {
                        "featureType": "poi",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    /*
                    {
                        "featureType": "administrative",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    }, */
                    {
                        "featureType": "landscape.natural",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                ]
            });
            const marker = new google.maps.Marker({
                position: {
                    lat: 50.0743105,
                    lng: 14.4163701,
                },
                map: map,
                draggable: true,
                icon: this.user.sex === "female" ? "/img/map-marker.png" : "/img/map-marker-men.png",
            });

            this.map = map;
            this.marker = marker;

            google.maps.event.addListener(map, "click", (event) => {
                this.marker.setPosition(event.latLng);
                this.marker.setVisible(true);
                this.value = undefined;
            });

            google.maps.event.addListener(marker, "dragend", () => {
                this.value = undefined;
            });

            this.autocomplete =
                new window.google.maps.places.AutocompleteService();
            this.geocoder = new window.google.maps.Geocoder();
        }, 300);
    },

    watch: {
        dialog: function () {
            this.$nextTick(() => {
                if (this.dialog) {
                    // move this.$refs.map element to this.$refs.map-dialog
                    this.$refs["map-dialog"].innerHTML = "";
                    this.$refs["map-dialog"].append(this.map.getDiv());
                } else {
                    this.$refs["map-holder"].innerHTML = "";
                    this.$refs["map-holder"].append(this.map.getDiv());
                }
            });
        },
        search: function () {
            if (!this.search || this.search.trim().length === 0) return;
            if (this.addressTimeout) clearTimeout(this.addressTimeout);

            this.addressTimeout = setTimeout(() => {
                this.loading = true;
                this.autocomplete.getPlacePredictions(
                    {
                        input: this.search,
                    },
                    (prediction, status) => {
                        if (status === "OK") {
                            this.items = prediction.map((item) => {
                                return {
                                    main_text:
                                        item.structured_formatting.main_text,
                                    secondary_text:
                                        item.structured_formatting
                                            .secondary_text,
                                    place_id: item.place_id,
                                };
                            });
                        }

                        this.loading = false;
                    }
                );
            }, 500);
        },
    },

    methods: {
        dateChanged() {

        },

        async post() {
            if (this.description == "" || this.situation == "" || this.message == "") {
                this.$root.$emit('notification', {
                    message: this.$t("Je nutné vyplnit všechna pole."),
                    duration: 10,
                    type: 'error'
                });
                return;
            }

            const geocodeResponse = await this.geocoder.geocode({
                location: {
                    lat: this.marker.getPosition().lat(),
                    lng: this.marker.getPosition().lng(),
                }
            });

            const address = geocodeResponse.results[0].formatted_address;

            let data = await this.$store.dispatch("putSearch", {
                address: address,
                lan: this.marker.getPosition().lat(),
                lon: this.marker.getPosition().lng(),

                seenAt: moment(
                    `${this.date} ${this.time}`,
                    "YYYY-MM-DD HH:mm"
                ).toISOString(),

                description: this.description,
                situation: this.situation,
                message: this.message,
            });

            this.$router.push({ name: "History", params: { rid: 0 } });
                    
            this.$root.$emit('notification', {
                message: this.$t("Hledání bylo přidáno."),
                duration: 5
            });
        },
        selected(item) {
            if (!item) return;

            const place_id = item.place_id;
            this.geocoder.geocode({ placeId: place_id }, (data) => {
                const location = data[0].geometry.location;

                if (this.$vuetify.breakpoint.mdAndDown) this.dialog = true;
                const loc = {
                    lat: location.lat(),
                    lng: location.lng(),
                };

                this.map.setCenter(loc);
                this.marker.setPosition(loc);
                this.map.setZoom(16);
            });
        },
        backToPosition() {
            this.map.setCenter(this.marker.getPosition());
        },
    },
};
</script>

<style scoped lang="scss">

.moveable {
    overflow-x: auto;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: #f3f3f3;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #7a9704;
        border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

.map-side {
    position: relative;
    height: calc(100vh - 48px); // ZMENA: calc(100vh - 48px);

    .backToPosition {
        position: absolute;
        bottom: 60px;
        left: 20px;
    }
}

.side-container {
    padding: 0;
    padding-bottom: 45px;
    //height: calc(100vh - 48px);
    
    flex-direction: column;

    >* {
        padding: 12px 24px;
    }

    > :not(.moveable) {
        flex-shrink: 0;
    }

    >.moveable {
        flex-grow: 2;
        overflow-x: auto;

        &::-webkit-scrollbar {
            width: 10px;
        }

        &::-webkit-scrollbar-track {
            background: #f3f3f3;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #7a9704;
            border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }
}

.remove-row {
    margin: 0;
}

.side .col-12 {
    padding: 5px;
}

.side p {
    font-size: 1em;
    text-align: left;
}

.height-100 {
    min-height: 100%;
}

.map-holder {
    height: 100%;
    cursor: url("/img/cross.png"), pointer;
}

.v-dialog .map-holder {
    height: calc(100vh - 56px);
}

.v-application--is-ltr .v-textarea.v-text-field--enclosed .v-text-field__slot textarea {
    background-color: transparent !important;
}

.map-icon {
    width: 30px;
    height: 30px;
    margin-left: 10px;
}

.label {
    font-size: 18px !important;
    font-weight: 700;
    line-height: 1.25rem;
    letter-spacing: 0.0178571429em !important;
    font-family: "Catamaran", sans-serif !important;
    margin-bottom: 10px !important;

    &.date {
        margin-bottom: 0px !important;
    }
}
</style>

<style lang="scss">
.v-alert.map-alert {
    cursor: pointer;
    >.v-alert__wrapper {
        width: 100%;

        >.v-alert__content {
            width: 100%;
            flex: initial;
        }
    }
}

.v-application--is-ltr .v-textarea.v-text-field--enclosed .v-text-field__slot {
    margin-right: -8px !important;
    margin-bottom: 8px;
}

.v-textarea.focused {
    textarea {
        height: 100px;
        transition: height 0.15s ease-in-out;
    }
}

.v-textarea textarea {
    height: 40px;
    transition: height 0.3s ease-in-out;
    padding-right: 8px !important;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: #f3f3f3;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #7a9704;
        border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

.map-mobile .map-holder {
    position: static !important;
}

@media only screen and (max-width: 992px) {
    .side-container {
        display: flex;
        overflow: hidden !important;
    }

    .esy-neco-2 {
        padding-bottom: 0 !important;
        transform: none !important;

        //height: 100% !important;
    }

    .mobile-insets .esy-neco-2 {
        height: 100% !important;
    }

    .esy-cool-button {
        width: 100%;
    }

    .esy-oprava {
        padding-right: 16px !important;
    }
}

</style>