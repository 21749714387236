<template>
    <div class="pa-0 height-100">
        <v-row class="height-100 remove-row" align="center">
            <v-col cols="12" md="3" class="side-container white">
                <v-row class="side remove-row" style="margin: 0; padding: 0;">
                    <v-col cols="6" style="margin: 0; padding: 0;">
                        <v-btn block dark x-large :class="active ? 'esy-active' : ''" @click="active = true" style="box-shadow: none; border-radius: 0; background: #2C3032; text-transform: none; font-weight: bold;">
                            {{ $t('Aktivní') }}
                        </v-btn>
                    </v-col>
                    <v-col cols="6" style="margin: 0; padding: 0;">
                        <v-btn block dark x-large :class="!active ? 'esy-active' : ''" @click="active = false" style="box-shadow: none; border-radius: 0; background: #2C3032; text-transform: none; font-weight: bold;">
                            {{ $t('Ukončené') }}
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="side remove-row moveable esy-panel-wrapper" style="padding: 24px;">
                    <div v-if="active && activeSearches.length === 0" style="width: 100%;">
                        <p class="pa-4" style="text-align: center; width: 100%;">{{ $t('Nemáte aktivní žádné hledání') }}.</p>
                    </div>
                    <div v-if="!active && unactiveSearches.length === 0" style="width: 100%;">
                        <p class="pa-4" style="text-align: center; width: 100%;">{{ $t('Žádné vaše hledání není ukončené') }}.</p>
                    </div>
                    <v-expansion-panels v-if="(active ? activeSearches : unactiveSearches).length > 0" accordion v-model="activeSearch" class="esy-expansion-panels">
                        <v-expansion-panel v-for="(item, i) in (active ? activeSearches : unactiveSearches)" :key="i">
                            <v-expansion-panel-header>
                                <span><span class="esy-counter">{{(active ? activeSearches : unactiveSearches).length - i}}.</span> {{ item.seenAt.format("DD. MM. YYYY HH:mm") }}</span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="text-body-1">
                                <v-row>
                                    <v-col cols="12">
                                        <p class="mb-0"><b>{{ $t('Místo setkání') }}</b></p>

                                        <p style="margin-bottom: 0;">{{ item.address }}</p>
                                    </v-col>
                                    <v-col cols="12">
                                        <p class="mb-0"><b>{{ $t('Termín setkání') }}</b></p>

                                        <p style="margin-bottom: 0;">{{ item.seenAt.fromNow() }}</p>
                                    </v-col>
                                    <v-col cols="12">

                                        <p class="mb-0"><b>{{ $t('Popis protějšku') }}</b></p>

                                        <p>{{ item.description }}</p>



                                        <p class="mb-0"><b>{{ $t('Situace při setkání') }}</b></p>

                                        <p>{{ item.situation }}</p>


                                        <p class="mb-0"><b>{{ $t('Zpráva') }}</b></p>

                                        <p style="margin-bottom: 0;">{{ item.message }}</p>
                                    </v-col>
                                    <v-col cols="12" v-if="item.active">
                                        <div class="d-flex justify-space-between flex-wrap">
                                            <v-btn plain @click="showOnMap(item.id);" class="d-flex d-md-none">{{ $t('Zobrazit na mapě') }}</v-btn>
                                            <v-btn plain color="red" @click="activateSearch(item, false, i)">{{ $t('Ukončit') }}</v-btn>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" v-if="!item.active">
                                        <div class="d-flex justify-space-between flex-wrap">
                                            <v-btn plain @click="showOnMap(item.id);" class="d-flex d-md-none">{{ $t('Zobrazit na mapě') }}</v-btn>
                                            <v-btn plain @click="activateSearch(item, true, i)">{{ $t('Obnovit') }}</v-btn>
                                            <v-btn plain color="red" @click="deleteSearch(item)">{{ $t('Smazat') }}</v-btn>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-row>
            </v-col>
            <v-col class="d-none d-md-block height-100 map-side pa-0 attachTo" cols="12" md="9" ref="map-holder">
                <div ref="map" class="map-holder"></div>
            </v-col>

            <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
                <v-card class="attachTo">
                    <v-toolbar dark color="primary" style="z-index: 999;">
                        <v-toolbar-title>{{ $t('Hledání') }}</v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-btn icon @click="dialog = false">
                            <v-icon>fas fa-times</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <div class="h-100 map-overlay" ref="map-dialog"></div>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: "SearchNew",

    computed: {
        user() {
            return this.$store.state.user;
        },
        searches() {
            return this.$store.state.searches.map(s => {
                return {
                    ...s,
                    seenAt: moment(s.seenAt)
                }
            }).sort((a, b) => {
                return a.seenAt.isBefore(b.seenAt) ? 1 : -1;
            });
        },
        activeSearches() {
            return this.searches.filter(search => search.active === true);
        },
        unactiveSearches() {
            return this.searches.filter(search => search.active === false);
        }
    },

    data: () => ({
        dialog: false,

        active: true,

        map: undefined,
        markers: [],

        activeSearch: null
    }),

    async mounted() {
        await this.$store.dispatch('getMySearches');

        setTimeout(() => {
            const map = new google.maps.Map(this.$refs.map, {
                zoom: 12,
                center: {
                    lat: 50.0743105,
                    lng: 14.4163701,
                },
                streetViewControl: false,
                disableDefaultUI: true,
                gestureHandling: "greedy",
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: [
                    {
                        "featureType": "poi",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    /*
                    {
                        "featureType": "administrative",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    }, */
                    {
                        "featureType": "landscape.natural",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                ]
            });

            this.map = map;

            this.spawnMarkers();

            console.log(this.$router.currentRoute.params.rid);
            if (this.$router.currentRoute.params.rid != undefined && this.activeSearches.length > 0)
                this.activeSearch = this.$router.currentRoute.params.rid; // rid = route id
        }, 600);
    },

    methods: {
        async activateSearch(search, active, i) {
            await this.$store.dispatch("patchSearch", {
                id: search.id,
                active: active
            });
            if(active) {
                this.$root.$emit('notification', {
                    message: this.$t("Gratulujeme! Váš požadavek na hledání byl úspěšně zveřejněn."),
                    duration: 6
                });
            } else {
                this.$root.$emit('notification', {
                    message: this.$t("Váš požadavek na hledání byl úspěšně skryt."),
                    duration: 6
                });
            }
            this.spawnMarkers();
        },

        async deleteSearch(search) {
            await this.$store.dispatch("deleteSearch", search.id);

            this.spawnMarkers();
        },

        spawnMarkers() {
            for(let marker of this.markers) {
                marker.setMap(null);
            }

            this.markers = [];

            for (const search of (this.active ? this.activeSearches : this.unactiveSearches)) {
                const marker = new google.maps.Marker({
                    position: {
                        lat: search.lan,
                        lng: search.lon,
                    },
                    map: this.map,
                    icon:
                        search.searchGender != "female"
                            ? "/img/map-marker.png"
                            : "/img/map-marker-men.png",

                    search: search
                });

                this.markers.push(marker);

                marker.addListener("click", () => {
                    this.activeSearch = (this.active ? this.activeSearches : this.unactiveSearches).findIndex(s => s.id === search.id);
                });
            }
        },
        showOnMap(id) {
            this.dialog = true;

            const search = (this.active ? this.activeSearches : this.unactiveSearches).find(s => s.id === id);
            this.map.setCenter({
                lat: search.lan,
                lng: search.lon,
            });

            this.markers.forEach(marker => {
                if (marker.search.id != id)
                    marker.setMap(null);
            });
        }
    },

    watch: {
        dialog: function () {
            this.$nextTick(() => {
                if (this.dialog) {
                    // move this.$refs.map element to this.$refs.map-dialog
                    this.$refs["map-dialog"].innerHTML = "";
                    this.$refs["map-dialog"].append(this.map.getDiv());
                } else {
                    this.$refs["map-holder"].innerHTML = "";
                    this.$refs["map-holder"].append(this.map.getDiv());

                    this.markers.forEach(marker => {
                        marker.setMap(this.map);
                    });
                }
            });
        },
        activeSearch: {
            handler(oldVal, newVal) {
                if (!this.map || !this.searches || this.activeSearch == null) return; 

                const search = (this.active ? this.activeSearches : this.unactiveSearches)[this.activeSearch];
                this.map.setCenter({
                    lat: search.lan,
                    lng: search.lon,
                });
                this.map.setZoom(17);
            },
            deep: true,
            immediate: true
        },
        active() {
            this.spawnMarkers();
        }
    },
};
</script>

<style scoped lang="scss">

.esy-active {
    background-color: #4084D8 !important;
    color: white;
    position: relative;
}

.esy-active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    box-shadow: none !important;

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent white transparent;
}

.esy-counter {
    display: inline-block;
    background: #2C3032;
    border-radius: 50%;
    color: white;
    margin-right: 8px;
    font-size: 14px;
    width: 24px;
    height: 24px;
    line-height: 26px;
    text-align: center;
    font-weight: 900;
}

.map-side {
    position: relative;
    height: calc(100vh - 48px);

    .backToPosition {
        position: absolute;
        bottom: 60px;
        left: 20px;
    }
}

.moveable {
    overflow-x: auto;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: #f3f3f3;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #7a9704;
        border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

.side-container {
    padding: 0;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    >.row {
        display: flex;
        flex-grow: 0;
    }

    >* {
        padding: 12px 24px;
    }

    > :not(.moveable) {
        flex-shrink: 0;
    }
}

.remove-row {
    margin: 0;
}

.side .col-12 {
    padding: 5px;
}

.side p {
    font-size: 1em;
    text-align: left;
}

.height-100 {
    min-height: 100%;
}

.map-holder {
    height: 100%;
    cursor: url("/img/cross.png"), pointer;
}

.map-overlay .map-holder {
    position: static !important;
}

.v-dialog .map-holder {
    height: calc(100vh - 56px);
}

.v-application--is-ltr .v-textarea.v-text-field--enclosed .v-text-field__slot textarea {
    background-color: transparent !important;
}

// Přestylování expansion panelu
@media only screen and (max-width: 992px) {
    .side-container {
        height: 100%;
    }

    .esy-panel-wrapper {
        padding: 0 !important;
    }

    body:not(.mobile-insets) .side-container {
        height: calc(100vh - 47px) !important;
    }

    .esy-panel-wrapper > div {
        border: none !important;
    }
}

</style>